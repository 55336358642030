import { isNil, uniq } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import type { AnalysisView, PrivatePortfolioNode } from 'venn-api';
import { getSpecificAnalysisView, viewEntity } from 'venn-api';
import type { AfterUnsavedChangeAction } from 'venn-components';
import { StudioPrintSettingsContext, UserContext, useAppPrintMode } from 'venn-components';
import {
  allocatorAnalysisSubject,
  analysisViewIdState,
  currentAnalysisView,
  openPrivateAllocatorPortfolio,
  originalAnalysisSubjectQuery,
  useSendSignal,
  useUnsavedChanges,
} from 'venn-state';
import { Notifications, NotificationType } from 'venn-ui-kit';
import {
  type CustomizableBlockSetting,
  isViewTemplate,
  type StudioTemplateState,
  AnalysisSubject,
  getNonTemplateType,
  getViewIdFromLocation,
  logExceptionIntoSentry,
  parseValueFromLocation,
  Routes,
  updateUrlParam,
  assertNotNil,
} from 'venn-utils';

import { trackViewOpened } from './studioUtils';
import { useSyncAllocatorConfigToView } from './useSyncAllocatorConfigToView';
import { useSyncPrivateAllocatorConfigToView } from './useSyncPrivateAllocatorConfigToView';

/** Handle Studio setup information, such as fetching new view, store view into localstorage and sync url */
const useStudioSetup = () => {
  const { hasPermission, currentContext } = useContext(UserContext);
  const { loadSettings: loadStudioPrintSettings } = useContext(StudioPrintSettingsContext);
  const isFetchingNewViewRef = useRef(false);
  const [draggingBlock, setDraggingBlock] = useState<CustomizableBlockSetting>();
  const history = useHistory();
  const location = useLocation<StudioTemplateState>();
  const { inPrintMode } = useAppPrintMode();
  const locationSavedId = parseValueFromLocation(location, 'savedId');

  const setPortfolioInPrivateAllocator = useRecoilCallback(
    ({ snapshot, set }) =>
      async (portfolio?: PrivatePortfolioNode, unsavedModifiedPortfolio?: PrivatePortfolioNode) => {
        const studioSubject = { privatePortfolioId: portfolio?.id };

        // portfolio that is passed into this function is coming from history.state and can be stale
        const portfolioFromServer = (await snapshot.getPromise(originalAnalysisSubjectQuery(studioSubject)))
          ?.privatePortfolio;

        if (isNil(portfolioFromServer)) {
          return;
        }

        set(openPrivateAllocatorPortfolio, portfolioFromServer);
        set(
          allocatorAnalysisSubject(studioSubject),
          new AnalysisSubject(unsavedModifiedPortfolio ?? portfolioFromServer, 'private-portfolio'),
        );
      },
    [],
  );

  const [baselineView, setBaselineView] = useState<AnalysisView | undefined>();
  const [firstOpeningOfTheView, setFirstOpeningOfTheView] = useState(false);
  const [isGlobalAnalysisRangeLoading, setIsGlobalAnalysisRangeLoading] = useState(false);
  const setCurrentAnalysisView = useSetRecoilState(currentAnalysisView);
  const currentViewId = useRecoilValue(analysisViewIdState);

  const [hasUnsavedRecoilChanges, setHasUnsavedRecoilChanges] = useUnsavedChanges();

  const [afterUnsavedChangesAction, setAfterUnsavedChangesAction] = useState<AfterUnsavedChangeAction>();

  const canCreateTemplate = useMemo(() => hasPermission('STUDIO_CREATE_TEMPLATE'), [hasPermission]);

  // Memoized because compareViewsHasDiff is expensive
  const hasUnsavedChange = useMemo(
    () => !inPrintMode && hasUnsavedRecoilChanges,
    [inPrintMode, hasUnsavedRecoilChanges],
  );
  const [isCheckingDuplicateReportName, setIsCheckingDuplicateReportName] = useState(false);
  const [isDuplicateReportName, setIsDuplicateReportName] = useState(false);

  const resetState = useSendSignal({ type: 'StudioReset' });

  useSyncAllocatorConfigToView();
  useSyncPrivateAllocatorConfigToView();

  const fetchNewView = useCallback(
    async (viewId?: string, localAnalysisView?: AnalysisView) => {
      // Prevent fetch new view while there is another one in progress
      if (isFetchingNewViewRef.current) {
        return;
      }

      isFetchingNewViewRef.current = true;
      resetState();
      const editTemplate = parseValueFromLocation(history.location, 'editTemplate') === 'true';
      const isReport = history.location.pathname.startsWith(Routes.REPORT_LAB_PATH);

      setIsGlobalAnalysisRangeLoading(true);

      setPortfolioInPrivateAllocator(undefined);
      setIsDuplicateReportName(false);
      setIsCheckingDuplicateReportName(false);
      loadStudioPrintSettings();

      if (!canCreateTemplate && editTemplate) {
        setBaselineView(undefined);
        isFetchingNewViewRef.current = false;
        setIsGlobalAnalysisRangeLoading(false);
        return;
      }

      let view: AnalysisView | undefined = localAnalysisView;

      try {
        if (viewId) {
          const { content: savedView } = await getSpecificAnalysisView(viewId);
          view = savedView;
          setBaselineView(view);
          trackViewOpened(view);
        } else {
          setBaselineView(undefined);
        }

        const uniqueSubjectIds = uniq(view?.subjects.map((s) => s.id) ?? []);

        const template = isViewTemplate(view?.analysisViewType);
        if (view) {
          if (template && !editTemplate) {
            view.id = undefined;
            view.name = undefined;
            view.analysisViewType = getNonTemplateType(view.analysisViewType);
            view.published = false;
            // When open a report from template, default to current context
            view.ownerContextId = currentContext ?? view.ownerContextId;
            updateUrlParam(history, 'REPLACE', 'savedId', undefined);
            // Auto open setup modal when it's a template but not in edit mode
            setFirstOpeningOfTheView(true);
          }
        }

        !editTemplate && updateUrlParam(history, 'REPLACE', 'editTemplate', undefined);

        setCurrentAnalysisView(assertNotNil(view));
        Promise.all(
          uniqueSubjectIds.map((subjectId) =>
            viewEntity(
              subjectId.toString(),
              viewId
                ? {
                    viewId,
                    viewType: view?.analysisViewType,
                  }
                : undefined,
            ),
          ),
        );
      } catch (e) {
        Notifications.notify(`Unable to load ${isReport ? 'report' : 'studio view'}`, NotificationType.ERROR);
        logExceptionIntoSentry(e);
        history.push(Routes.HOME_PATH);
      } finally {
        isFetchingNewViewRef.current = false;
        setIsGlobalAnalysisRangeLoading(false);
        setHasUnsavedRecoilChanges(view?.id === undefined);
      }
    },
    [
      resetState,
      history,
      setPortfolioInPrivateAllocator,
      loadStudioPrintSettings,
      canCreateTemplate,
      setCurrentAnalysisView,
      setHasUnsavedRecoilChanges,
      currentContext,
    ],
  );

  const checkHasUnsavedState = useCallback(
    (proceedCallback: () => void) => {
      if (isFetchingNewViewRef.current) {
        return;
      }

      if (hasUnsavedChange && baselineView) {
        // Un applied change would prompt warning modal
        setAfterUnsavedChangesAction({
          proceedCallback,
          cancelCallback: undefined,
          navigateToNew: true,
        });
      } else {
        proceedCallback();
      }
    },
    [hasUnsavedChange, baselineView],
  );

  const handleHistoryStateUpdate = useCallback(
    (id: string | undefined, newDocument: AnalysisView) => {
      checkHasUnsavedState(() => {
        fetchNewView(id, newDocument);
      });
    },
    [checkHasUnsavedState, fetchNewView],
  );

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const newDocument = location.state.newDocument;
    const newDocumentId = location.state.id || locationSavedId;
    if (newDocument) {
      handleHistoryStateUpdate(newDocumentId, newDocument);
    }
    const openPrivatePortfolioAllocator = location.state.openAllocatorForPrivatePortfolio;
    const modifiedUnsavedPortfolio = location.state.modifiedUnsavedPortfolio;
    if (!isNil(openPrivatePortfolioAllocator)) {
      setPortfolioInPrivateAllocator(openPrivatePortfolioAllocator, modifiedUnsavedPortfolio);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    const newDocument = location.state?.newDocument;
    if (newDocument) {
      return;
    }
    const viewIdFromLocation = getViewIdFromLocation(history.location);
    if (viewIdFromLocation && currentViewId !== viewIdFromLocation) {
      checkHasUnsavedState(() => {
        fetchNewView(viewIdFromLocation);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Reset all view state when studio / report lab dismounts
  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only reset state on dismount
  }, []);

  const studioSetupValue = useMemo(
    () => ({
      baselineView,
      isFetchingNewViewRef,
      setBaselineView,
      draggingBlock,
      setDraggingBlock,
      isGlobalAnalysisRangeLoading,
      setIsGlobalAnalysisRangeLoading,
      firstOpeningOfTheView,
      setFirstOpeningOfTheView,
      hasUnsavedChange,
      afterUnsavedChangesAction,
      setAfterUnsavedChangesAction,
      isDuplicateReportName,
      setIsDuplicateReportName,
      isCheckingDuplicateReportName,
      setIsCheckingDuplicateReportName,
    }),
    [
      afterUnsavedChangesAction,
      baselineView,
      draggingBlock,
      firstOpeningOfTheView,
      hasUnsavedChange,
      isCheckingDuplicateReportName,
      isDuplicateReportName,
      isGlobalAnalysisRangeLoading,
    ],
  );
  return studioSetupValue;
};

export default useStudioSetup;

import React, { useEffect, useState } from 'react';
import FieldWithLabel from './components/FieldWithLabel';
import MetaHeader from '../MetaHeader';
import { FieldWithLabelHeader, FieldWithLabelHeaderLeft } from './components/styled';
import { Button, GetColor, Hint, Icon, Label, Link as LinkStyle, LoadingSize, Spinner, TextAlign } from 'venn-ui-kit';
import { Dates, Numbers } from 'venn-utils';
import styled, { css } from 'styled-components';
import { isNil } from 'lodash';
import moment from 'moment';
import Input from '../../input/Input';
import { useFundCustomMetadata, useUpdateFundYield } from '../../utils';

interface YieldRowProps {
  fundId: string;
  canEditYield: boolean;
  isInvestmentEditable: boolean;
}

const INPUT_ROW_CLASSNAME = 'yield_value_input_row_classname';

const YIELD_MIN = -99.99;
const YIELD_MAX = 999.99;
export const YIELD_BOUNDS_MESSAGE = `Yield value must be between ${YIELD_MIN}% and ${YIELD_MAX}%.`;

export const CONTACT_ADMIN_TO_EDIT_YIELD_HINT =
  'Please contact your organization administrator in order to set and update Estimated Annual Yield values within your workspace.';
export const ADMIN_EDIT_YIELD_HINT =
  'Venn displays and uses the above organizationally-defined yield values for calculations. These values need to be kept up to date by the organization, or cleared if not in use. Empty yield values will be calculated as 0.0% when analysis is performed.';

function isYieldValueValid(value: number | undefined): boolean {
  return isNil(value) || (YIELD_MIN <= value && value <= YIELD_MAX);
}

function toPercent(value: number | undefined): number | undefined {
  return isNil(value) ? undefined : Number((value * 100).toFixed(2));
}

function fromPercent(value: number | undefined): number | undefined {
  return isNil(value) ? undefined : Number((value / 100).toFixed(4));
}

function fromString(value: string): number | undefined {
  if (isNil(value) || value.length === 0 || Number.isNaN(Number(value))) {
    return undefined;
  }
  return Number(value);
}

const YieldRow = ({ fundId, canEditYield, isInvestmentEditable }: YieldRowProps) => {
  const { data: customMetadata, isLoading } = useFundCustomMetadata(fundId);
  const yieldData = customMetadata?.yield;

  const [yieldString, setYieldString] = useState<string>('');

  useEffect(() => {
    setYieldString(`${toPercent(yieldData?.percentageYield)}`);
  }, [yieldData]);

  const { updateYield, isSaving } = useUpdateFundYield(fundId);

  const hasValidationError = !isYieldValueValid(fromString(yieldString));

  return (
    <StyledFieldWithLabel hasErrorMessage={hasValidationError} showDisabledStyling={!isInvestmentEditable}>
      <FieldWithLabelHeader>
        <FieldWithLabelHeaderLeft>
          <MetaHeader label="Estimated Annual Yield" info={YIELD_BOUNDS_MESSAGE} />
        </FieldWithLabelHeaderLeft>
        {canEditYield && (
          <Label>
            <StyledLinkStyle
              onClick={() => {
                if (isNil(yieldData?.percentageYield)) {
                  return;
                }
                updateYield(undefined);
              }}
              disabled={isNil(yieldData?.percentageYield)}
            >
              Clear Yield Value <ClearIcon type="circle-x" />
            </StyledLinkStyle>
          </Label>
        )}
      </FieldWithLabelHeader>

      {canEditYield && (
        <FormWrapper className={INPUT_ROW_CLASSNAME}>
          <StyledInput
            type="number"
            placeholder="--"
            value={yieldString}
            onChange={setYieldString}
            onBlur={() => {
              const inputYieldValue = fromString(yieldString);
              const roundedValue = isNil(inputYieldValue) ? undefined : Number(inputYieldValue.toFixed(2));
              if (roundedValue === fromString(yieldString)) {
                return;
              }
              setYieldString(`${roundedValue}`);
            }}
            textAlign={TextAlign.Right}
            error={hasValidationError}
            errorMessage={hasValidationError ? YIELD_BOUNDS_MESSAGE : undefined}
            errorAlign="right"
            selectOnFocus
          />
          %
          <Button
            dominant
            dense
            disabled={
              fromString(yieldString) === toPercent(yieldData?.percentageYield) ||
              hasValidationError ||
              isLoading ||
              isSaving
            }
            onClick={() => updateYield(fromPercent(fromString(yieldString)))}
          >
            {isSaving ? <Spinner size={LoadingSize.nano} /> : 'SAVE'}
          </Button>
        </FormWrapper>
      )}

      <LeftRight>
        {!canEditYield && <>{Numbers.safeFormatPercentage(yieldData?.percentageYield, 2)}</>}
        <Hint>
          Last updated on:{' '}
          {isNil(yieldData?.lastUpdated) ? '--' : Dates.toDDMMMYYYY(moment.utc(yieldData.lastUpdated).valueOf())}
        </Hint>
      </LeftRight>

      <Hint>{canEditYield ? ADMIN_EDIT_YIELD_HINT : CONTACT_ADMIN_TO_EDIT_YIELD_HINT}</Hint>
    </StyledFieldWithLabel>
  );
};

export default YieldRow;

const ClearIcon = styled(Icon)`
  margin-left: 5px;
`;

const StyledFieldWithLabel = styled(FieldWithLabel)<{ hasErrorMessage: boolean }>`
  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  ${({ hasErrorMessage }) =>
    hasErrorMessage &&
    css`
      & > .${INPUT_ROW_CLASSNAME} {
        margin-bottom: 30px;
      }
    `}
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * {
    min-height: 35px;

    :last-child {
      margin-left: 20px;
    }
  }
`;

const StyledInput = styled(Input)`
  flex-grow: 1;
  margin-right: 4px;
`;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 18px;
`;

const StyledLinkStyle = styled(LinkStyle)<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? GetColor.MidGrey2 : GetColor.Primary.Dark)};
`;

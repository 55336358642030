import React, { useCallback, useContext, useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState, type CallbackInterface } from 'recoil';
import {
  getCheckedParents,
  Loading,
  StudioButton,
  Subtitle1,
  Icon,
  GetColor,
  type DropMenuCheckboxItem,
  Warning,
} from 'venn-ui-kit';
import {
  type CustomBlockTypeEnum,
  type CustomNotablePeriod,
  isPrivatesPerformanceBlock,
  isFactorTrendMetric,
  useMetricsParser,
  useVennDialog,
  useHasFF,
  analyticsService,
  getSubjectFromRequestSubject,
  assertExhaustive,
  type CustomizableMetric,
} from 'venn-utils';
import type { ReorderableItem } from 'venn-components';
import {
  EmptyState,
  SelectableMetricsGroup,
  UserContext,
  ReorderableListDefaultItemRenderer,
  type MetricWithError,
} from 'venn-components';
import type { InfoGraphicTypeEnum } from 'venn-api';
import { compact, flatten, isNil, map, cloneDeep } from 'lodash';
import styled from 'styled-components';
import {
  availableFactorMetrics,
  blockAllFactorsSelected,
  blockCustomMetricSettingsState,
  blockFactorIds,
  blockLimitedRequestSubjects,
  blockMetrics,
  blockSettings,
  blockSubjects,
  customizedBlock,
  MetricError,
  predefinedNotablePeriods,
  useMetricsErrors,
  viewCustomNotablePeriods,
  viewSelectedNotablePeriods,
  type BlockId,
  pcBlock_customField_gridData,
  type PortfolioComparisonColumnGroupKey,
  createPCBlockColumnKey,
  subjectToKeyString,
  type PCBlockCustomMetricRow,
  cleanEmptyFromGrid,
  createChangesKey,
} from 'venn-state';
import { PanelSection } from '../../../PanelSection';
import AddCustomPeriod from './components/AddCustomPeriod';
import PrivatesCashflowPacingSettingsTooltip from './components/PrivatesCashflowPacingSettingsTooltip';
import { useCustomMetrics } from './hooks/useCustomMetrics';
import { CustomMetricsDialog } from './CustomMetricDialog';
import { InvestmentGroupInformationSection } from './InvestmentGroupInformationSection';

interface MetricsTabProps {
  selected: BlockId;
}

interface Option {
  key: string;
  label: string;
}

/**
 * null and undefined returned by this function are treated differently
 * null means no error and we will clear all existing errors for the block
 * undefined means skip error processing (it might be handled somewhere else for this given block)
 * this is needed to avoid race condition so that we don't call setMetricErrors for the same block in different places
 */
const getBlockError = (
  metricsLength: number,
  blockType?: CustomBlockTypeEnum,
  infographicType?: InfoGraphicTypeEnum,
): MetricError | null | undefined => {
  switch (infographicType) {
    case 'SCATTER': {
      if (metricsLength > 2) {
        return MetricError.TWO_METRICS;
      }
      break;
    }
    case 'CORRELATION': {
      if (metricsLength > 1) {
        return MetricError.ONE_METRIC;
      }
    }
  }

  if (!isNil(blockType) && isPrivatesPerformanceBlock(blockType)) {
    return undefined;
  }

  return null;
};

const MAX_CUSTOM_FIELDS = 20;

const MetricsSection = ({ selected }: MetricsTabProps) => {
  const { dialogRef, open } = useVennDialog();
  const { selectedCustomMetrics } = useCustomMetrics(selected);
  const blockSetting = useRecoilValue(blockSettings(selected));
  const setSelectedMetrics = useRecoilCallback((recoil) => async (metrics: string[]) => {
    // For blocks with custom fields, we need to ensure we clean up state related to deleted custom fields
    if (blockSetting.supportsCustomMetrics) {
      switch (blockSetting.customBlockType) {
        case 'PORTFOLIO_COMPARISON':
          await changePortfolioComparisonMetrics(recoil, selected, metrics);
          break;
        default:
          assertExhaustive(blockSetting);
      }
    }

    recoil.set(blockMetrics(selected), metrics);
  });
  const setSelectedFactors = useSetRecoilState(blockFactorIds(selected));
  const [allFactorsSelected, setAllFactorsSelected] = useRecoilState(blockAllFactorsSelected(selected));
  const block = useRecoilValue(customizedBlock(selected));
  const allNotablePeriods = useRecoilValue(predefinedNotablePeriods);
  const factorMetrics = useRecoilValue(availableFactorMetrics);
  const [selectedNotablePeriodIds, setSelectedNotablePeriodIds] = useRecoilState(viewSelectedNotablePeriods(selected));
  const [customNotablePeriods, setCustomNotablePeriods] = useRecoilState(viewCustomNotablePeriods(selected));
  const subjects = useRecoilValue(blockSubjects(selected));
  const { hasPermission } = useContext(UserContext);

  const isReadOnly = !hasPermission('STUDIO_EDIT_METRICS');
  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const isPrivatesCashflowPacingBlock = blockSetting.customBlockType === 'PRIVATE_CASH_FLOW';
  const shouldShowCashflowPacingTooltip = hasPrivatesCashFlowSettingsFF && isPrivatesCashflowPacingBlock;

  const {
    selectedMetrics,
    groupedMetrics,
    selectedFactors,
    groupedFactors,
    selectedNotablePeriods,
    groupedNotablePeriods,
  } = useMetricsParser(
    blockSetting,
    block,
    factorMetrics,
    allNotablePeriods,
    selectedNotablePeriodIds,
    customNotablePeriods,
  );

  const onFilterMetricChange = useCallback(
    (items: DropMenuCheckboxItem[]) => {
      const selectedMetrics = getSelectedValues(items);
      setSelectedMetrics([...selectedMetrics, ...selectedCustomMetrics.map((metric) => metric.key)]);
    },
    [setSelectedMetrics, selectedCustomMetrics],
  );

  const onFilterFactorChange = useCallback(
    (items: DropMenuCheckboxItem[]) => {
      const selectedFactors = getSelectedValues(items);
      setSelectedFactors(selectedFactors);
      const allSelected = factorMetrics.every((f) => selectedFactors.includes(f.id));
      if (allSelected !== allFactorsSelected) {
        setAllFactorsSelected(allSelected);
      }
    },
    [allFactorsSelected, factorMetrics, setAllFactorsSelected, setSelectedFactors],
  );

  const onFilterPeriodsChange = useCallback(
    (items: DropMenuCheckboxItem[]) => {
      const selectedIds = getSelectedValues(items).map((stringValue) => Number.parseInt(stringValue, 10));
      setSelectedNotablePeriodIds(selectedIds);
      setCustomNotablePeriods((current) =>
        current?.map((period) => ({
          ...period,
          selected: selectedIds.includes(period.id),
        })),
      );
    },
    [setCustomNotablePeriods, setSelectedNotablePeriodIds],
  );

  const onReorderMetrics = useCallback(
    (newMetrics: ReorderableItem[][]) => {
      const selectedMetrics = flatten(newMetrics).map((m) => m.key);

      setSelectedMetrics(selectedMetrics);
    },
    [setSelectedMetrics],
  );

  const onReorderFactor = useCallback(
    (newMetrics: ReorderableItem[][]) => {
      const selectedFactors = flatten(newMetrics).map((m) => m.key);
      setSelectedFactors(selectedFactors);
      const allSelected = factorMetrics.every((f) => selectedFactors.includes(f.id));
      if (allSelected !== allFactorsSelected) {
        setAllFactorsSelected(allSelected);
      }
    },
    [allFactorsSelected, factorMetrics, setAllFactorsSelected, setSelectedFactors],
  );

  const onReorderPeriods = useCallback(
    (reorderedPeriods: ReorderableItem[][]) => {
      const selectedIds = flatten(reorderedPeriods).map(({ key }) => Number.parseInt(key, 10));
      setSelectedNotablePeriodIds(selectedIds);
    },
    [setSelectedNotablePeriodIds],
  );

  const onCreateCustomPeriod = useCallback(
    (newPeriod: CustomNotablePeriod) => {
      setSelectedNotablePeriodIds((current) => [
        ...(current ?? allNotablePeriods.map((period) => period.id)),
        newPeriod.id,
      ]);
      setCustomNotablePeriods((current) => [
        ...(current ?? []),
        {
          ...newPeriod,
          selected: true,
        },
      ]);
    },
    [allNotablePeriods, setCustomNotablePeriods, setSelectedNotablePeriodIds],
  );

  const showFactorSelector = (
    selectedMetrics: Option[],
    blockType?: CustomBlockTypeEnum,
    hasFactors = false,
  ): boolean => {
    if (!hasFactors) return false;

    switch (blockType) {
      case 'TIMESERIES':
        return isFactorTrendMetric(map(selectedMetrics, 'key'));
      default:
        return true;
    }
  };

  const showNotablePeriodSelector = blockSetting.customBlockType === 'NOTABLE_PERIODS';
  const showPeerGroupSelector = blockSetting.customBlockType === 'PEER_GROUPS';
  const showNotablePeriodsError = !subjects.length;
  const showFactors = showFactorSelector(
    flatten(selectedMetrics),
    blockSetting.customBlockType,
    blockSetting.hasFactors,
  );
  const showMetrics = groupedMetrics.length > 0;
  const showCustomMetrics = blockSetting.customBlockType === 'PORTFOLIO_COMPARISON';
  const { appendMetricErrors } = useMetricsErrors(selected);
  useEffect(() => {
    const blockLevelErrorMsg = getBlockError(
      flatten(selectedMetrics).length,
      blockSetting.customBlockType,
      block.infoGraphicType,
    );

    if (!isNil(blockLevelErrorMsg)) {
      appendMetricErrors([
        {
          metricKey: 'blockLevel',
          error: blockLevelErrorMsg,
        },
      ]);
    }
  }, [appendMetricErrors, selectedMetrics, block.infoGraphicType, blockSetting.customBlockType]);

  return (
    <>
      {showMetrics && (
        <PanelSection
          className="qa-metrics-section"
          header={
            <HeaderDiv
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', width: '100%' }}
            >
              Metrics
              {showCustomMetrics && (
                <HeaderButtonsDiv>
                  <StudioButton
                    onClick={open}
                    size="medium"
                    disabled={selectedCustomMetrics.length >= MAX_CUSTOM_FIELDS}
                  >
                    Add custom field
                  </StudioButton>
                  <CustomMetricsDialog blockId={selected} ref={dialogRef} />
                </HeaderButtonsDiv>
              )}
            </HeaderDiv>
          }
        >
          <SelectableMetricsGroup
            selectedBlockId={selected}
            onChangeFilter={onFilterMetricChange}
            items={groupedMetrics}
            selectedMetricText="Metric"
            reorderableItemGroups={selectedMetrics}
            reorderableItemRenderer={(item) => <ReordererableMetricRenderer blockId={selected} item={item} />}
            onReorderItemsInGroups={onReorderMetrics}
            className="qa-metrics-dropdown"
            disabled={isReadOnly}
          />
          {selectedMetrics.flat().some((metric) => metric.editable) && (
            <Warning
              icon="pen-field"
              iconPrefix="fas"
              text="Custom Field inputs do not automatically update to reflect portfolio changes. Please make sure to review after any changes are made."
            />
          )}
          {shouldShowCashflowPacingTooltip && <PrivatesCashflowPacingSettingsTooltip />}
        </PanelSection>
      )}
      {showFactors && (
        <PanelSection header="Factors">
          <SelectableMetricsGroup
            selectedBlockId={selected}
            onChangeFilter={onFilterFactorChange}
            items={groupedFactors}
            selectedMetricText="Factor"
            reorderableItemGroups={[selectedFactors]}
            onReorderItemsInGroups={onReorderFactor}
            className="qa-factors-dropdown"
            disabled={isReadOnly}
          />
        </PanelSection>
      )}
      {showNotablePeriodSelector && (
        <PanelSection header="Notable Periods">
          {showNotablePeriodsError ? (
            <CenteredEmptyState header="">
              <Subtitle1>Select at least one object to configure notable periods.</Subtitle1>
            </CenteredEmptyState>
          ) : isNil(groupedNotablePeriods) || isNil(selectedNotablePeriods) ? (
            <Loading />
          ) : (
            <>
              <SelectableMetricsGroup
                selectedBlockId={selected}
                onChangeFilter={onFilterPeriodsChange}
                items={groupedNotablePeriods}
                selectedMetricText="Period"
                reorderableItemGroups={[selectedNotablePeriods]}
                onReorderItemsInGroups={onReorderPeriods}
                className="qa-periods-dropdown"
                disabled={isReadOnly}
              />
              <AddCustomPeriod onAddCustomPeriod={onCreateCustomPeriod} />
            </>
          )}
        </PanelSection>
      )}
      {showPeerGroupSelector && <InvestmentGroupInformationSection selectedBlockId={selected} />}
    </>
  );
};

export default MetricsSection;

const ReordererableMetricRenderer = ({ item, blockId }: { item: MetricWithError; blockId: string }) => {
  const { dialogRef, open } = useVennDialog();
  return (
    <ReorderableMetricContainer>
      <ReorderableListDefaultItemRenderer item={item} />
      {item.editable && (
        <>
          <EditButton>
            <Icon type="pen" onClick={open} />
          </EditButton>
          <CustomMetricsDialog blockId={blockId} ref={dialogRef} metric={item} />
        </>
      )}
    </ReorderableMetricContainer>
  );
};

const ReorderableMetricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  margin-right: 8px;
`;

const EditButton = styled.button`
  i {
    font-size: 12px;
    color: ${GetColor.Grey};
    :hover {
      color: ${GetColor.Primary.Dark};
      cursor: pointer;
    }
  }
`;

const getSelectedValues = (items: DropMenuCheckboxItem[]) => {
  const checkedParents = getCheckedParents(items);
  const checkedParentValues = checkedParents.map((item) => item.value);
  // Filter out parent values
  return compact(
    items.filter((item) => item.checked && !checkedParentValues.includes(item.value)).map((item) => item.value),
  );
};

const CenteredEmptyState = styled(EmptyState)`
  text-align: center;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const HeaderButtonsDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 8px;
`;

async function changePortfolioComparisonMetrics(recoil: CallbackInterface, blockId: BlockId, newMetricKeys: string[]) {
  const currentRequestSubjects = await recoil.snapshot.getPromise(blockLimitedRequestSubjects(blockId));
  const currSubjects = currentRequestSubjects.map((subject) => subject && getSubjectFromRequestSubject(subject));

  const oldCustomMetrics = await recoil.snapshot.getPromise(blockCustomMetricSettingsState(blockId));
  const deletedMetrics = oldCustomMetrics.filter((metric) => !newMetricKeys.includes(metric.key));
  let cellsCleared = 0;

  recoil.set(pcBlock_customField_gridData(blockId), (oldGrid) => {
    const newGrid = cloneDeep(oldGrid);
    for (const rowObject of Object.values(newGrid)) {
      if (rowObject) {
        for (const subject of currSubjects) {
          deleteSubjectFromRow(deletedMetrics, rowObject, subjectToKeyString(subject));
        }
        deleteSubjectFromRow(deletedMetrics, rowObject, createChangesKey(currSubjects));
      }
    }
    analyticsService.customMetricsCleared({ type: 'Metric', cellsCleared });
    return cleanEmptyFromGrid(newGrid);
  });

  function deleteSubjectFromRow(
    deletedMetrics: CustomizableMetric[],
    rowToModify: PCBlockCustomMetricRow,
    subjectKey: PortfolioComparisonColumnGroupKey,
  ) {
    for (const metric of deletedMetrics) {
      const columnKey = createPCBlockColumnKey(subjectKey, metric.key);
      if (rowToModify[columnKey] !== undefined) {
        cellsCleared++;
      }
      delete rowToModify[columnKey];
    }
  }
}

import React from 'react';
import type { CustomBlockTypeEnum, CustomizableBlockSetting, PageType } from 'venn-utils';
import { asyncNoop } from 'venn-utils';
import type { DropMenuItem } from 'venn-ui-kit';
import type { AnalysisView } from 'venn-api';
import { noop } from 'lodash';
import type { Layout } from 'react-grid-layout';
import type { BlockId } from 'venn-state';

/** Settings for Section pages; no other pages use these settings. */
export interface SectionSettings {
  topText: string;
  title: string;
  subTitle: string;
}

/** Settings for Title pages; no other pages use these settings. */
export interface TitleSettings {
  caption?: string;
  title: string;
  subTitle: string;
  additionalText?: string;
}

type BasePage = {
  type: PageType;
  /** Contains specifications for how to lay out blocks on a grid page. May not be defined for non-grid pages. */
  layout: Layout[];
};

export type TitlePageT = BasePage & {
  type: PageType.TITLE;
  titleSettings: TitleSettings;
};

export type SectionPageT = BasePage & {
  type: PageType.SECTION;
  sectionSettings: SectionSettings;
};

/**
 * Data object for a Report Lab Grid page including the block layouts on the page.
 *
 */
export type Page =
  | (BasePage & { type: PageType.GRID | PageType.CONTENTS | PageType.DISCLOSURE })
  | TitlePageT
  | SectionPageT;

export interface SaveArgs {
  name?: string;
  currentAnalysisState?: AnalysisView;
}

export interface AfterUnsavedChangeAction {
  /** Don't show previous view's saving message if navigate to different view */
  navigateToNew?: boolean;
  /** Don't provide the option to discard change and continue to print/share when it's a draft view */
  hideDiscardBtn?: boolean;
  /** Show custom message in the modal */
  customMessage?: string;
  /** Show custom primary action label */
  customPrimaryLabel?: string;
  /**
   * Invoked when the user either proceeds or saves and proceeds.
   * Optionally pass in `analysisViewId` for cases where the new ID is created but the React state hasn't had a chance to propagate.
   * */
  proceedCallback: (analysisViewName: string | undefined, analysisViewId: string | undefined) => void;
  /** Invoked when the user cancels or discards the modal, without proceeding. */
  cancelCallback: (() => void) | undefined;
}

interface StudioGlobalState {
  isSaving: boolean;
  hasUnsavedChange: boolean;
  noAccessModifiedView: boolean;
  isDuplicateReportName: boolean;
  isCheckingDuplicateReportName: boolean;
  blockOptions: DropMenuItem<CustomizableBlockSetting>[];
  // Flag to prevent available range rendering when fetching new view.
  // Use mutable ref to take advantage of the instant update
  isFetchingNewViewRef: React.MutableRefObject<boolean>;
  // callback for unsaved modal
  afterUnsavedChangesAction?: AfterUnsavedChangeAction;
  // Prevent the analysis call when analysis depends on global range but it's still loading
  isGlobalAnalysisRangeLoading?: boolean;
  draggingBlock?: CustomizableBlockSetting;
  reportName: string;
  reportNameValue: string;
  isOpenReportConfigModal?: boolean;
}
export interface PageInsertOptions {
  pageNumber: number;
  layout: Layout[];
}

interface StudioGlobalAction {
  setBaselineView: (studio?: AnalysisView) => void;
  onBlockReorderUp: (refId: string) => void;
  onBlockReorderDown: (refId: string) => void;
  onDeleteBlock: (blockId: string) => void;
  onInsertBlock: (
    item: DropMenuItem<CustomizableBlockSetting>,
    insertIndex?: number,
    pageInsertOptions?: PageInsertOptions,
  ) => Promise<string | undefined>;
  onDuplicateBlock: (
    blockId: BlockId,
    insertIndex: number,
    customBlockType?: CustomBlockTypeEnum,
    pageInsertOptions?: PageInsertOptions,
  ) => void;
  onDuplicatePage: (pageNumber: number) => void;
  onSave?: () => Promise<{ savedName?: string; savedId?: string }>;
  onSaveAs: (name: string, ownerContextId?: string) => void;
  onExport: (isInternal: boolean) => void;
  onExcelExport: () => void;
  onPdfExport: (isInternal: boolean) => Promise<void>;
  setAfterUnsavedChangesAction: (action?: AfterUnsavedChangeAction) => void;
  handleAfterUnsavedChangesAction: (event: 'cancel' | 'proceed' | 'saveAndProceed') => Promise<void>;
  setFirstOpeningOfTheView: (open: boolean) => void;
  openReportConfigModal: () => void;
  closeReportConfigModal: () => void;
  setDraggingBlock: (block: CustomizableBlockSetting | undefined) => void;
  onDeletePage: (pageNumber: number) => void;
  onAddNewPage: (page: Page) => void;
  setReportName: (name: string) => void;
  setReportNameValue: (name: string) => void;
  onChangeReportName: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void;
  setIsDuplicateReportName: (isDuplicateReportName: boolean) => void;
  setIsCheckingDuplicateReportName: (isChecking: boolean) => void;
}

export interface StudioContext extends StudioGlobalState, StudioGlobalAction {}

export const defaultStudioContextValue: StudioContext = {
  isSaving: false,
  hasUnsavedChange: false,
  noAccessModifiedView: false,
  isDuplicateReportName: false,
  isCheckingDuplicateReportName: false,
  blockOptions: [],
  isFetchingNewViewRef: { current: false },
  setBaselineView: noop,
  onBlockReorderUp: noop,
  onBlockReorderDown: noop,
  onDeleteBlock: noop,
  onInsertBlock: () => Promise.resolve(undefined),
  onDuplicateBlock: noop,
  onSaveAs: noop,
  onExport: noop,
  onPdfExport: asyncNoop,
  onExcelExport: noop,
  setAfterUnsavedChangesAction: noop,
  handleAfterUnsavedChangesAction: asyncNoop,
  setFirstOpeningOfTheView: noop,
  setDraggingBlock: noop,
  onDeletePage: noop,
  onAddNewPage: noop,
  reportName: '',
  reportNameValue: '',
  setReportName: noop,
  setReportNameValue: noop,
  onChangeReportName: noop,
  onDuplicatePage: noop,
  setIsDuplicateReportName: noop,
  setIsCheckingDuplicateReportName: noop,
  openReportConfigModal: noop,
  closeReportConfigModal: noop,
};

/**
 * Performance note: Consuming StudioContext will cause many rerenders when any of the very many fields in StudioContext changes.
 *
 * Consider subscribing instead to a subContext such as {@link StuidoEditModeContext},
 * which only causes rerenders if that specific value changes.
 *
 * TODO(VER-87): consider making use of https://github.com/dai-shi/use-context-selector
 */
export const StudioContext = React.createContext(defaultStudioContextValue);
StudioContext.displayName = 'StudioContext';

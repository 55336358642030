/*
 * Venn Platform API 1.0.0 Operations for "funds"
 * Automatically generated file - do not modify!
 */

import type {
  AutoproxyResponse,
  CompositeBenchmark,
  CustomMetadata,
  FilterTypes,
  FrequencyEnum,
  Fund,
  FundCorrelation,
  FundEntities,
  FundMetadata,
  FundMetadataOptions,
  FundQuery,
  FundRestrictionEntry,
  FundReturnsRange,
  ProxyMetrics,
  ProxyOption,
  ProxyTypeEnum,
  RemoteTypeEnum,
  SimpleFund,
  UpdateTypeEnum,
  YieldMetric,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Add funds to restricted list */
export async function addFundRestrictions(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/funds/restricted';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Change the frequency for a fund. */
export async function updateFundFrequency(
  id: string,
  body?: FrequencyEnum,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/frequency`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Creates a new fund. */
export async function createFund(body?: Partial<Fund>, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/funds/create';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a collection of funds */
export async function deleteFunds(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/funds';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a fund */
export async function deleteFund(fundId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/${encodeURIComponent(fundId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a system fund */
export async function deleteSystemFund(
  fundId: string,
  remoteType?: RemoteTypeEnum,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/system/${encodeURIComponent(fundId)}${buildQuery({ remoteType })}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a system fund */
export async function deleteSystemFunds(
  remoteType?: RemoteTypeEnum,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/system/delete${buildQuery({ remoteType })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Find system funds with dependent workspaces */
export async function findSystemFundDependentWorkspaces(
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: number[] }>> {
  const endpoint = '/api/funds/dependents/system';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: number[] } = (await response.json()) as { [key: string]: number[] };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Force delete a collection of funds */
export async function forceDeleteCustodianFunds(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/funds/custodian/force';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of fund entities */
export async function getEntitiesForFund(
  fundId: string,
  email?: string,
  signal?: AbortSignal,
): Promise<OperationResult<FundEntities>> {
  const endpoint = `/api/funds/${encodeURIComponent(fundId)}/entities${buildQuery({ email })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundEntities = (await response.json()) as FundEntities;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of fund entities for each requested fund */
export async function getEntitiesForFunds(
  email?: string,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<FundEntities[]>> {
  const endpoint = `/api/funds/entities${buildQuery({ email })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundEntities[] = (await response.json()) as FundEntities[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get collection of historical restricted funds */
export async function getHistoricalRestrictedFunds(
  fundId?: string,
  start?: number,
  end?: number,
  signal?: AbortSignal,
): Promise<OperationResult<FundRestrictionEntry[]>> {
  const endpoint = `/api/funds/restricted/history${buildQuery({ fundId, start, end })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundRestrictionEntry[] = (await response.json()) as FundRestrictionEntry[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get collection of restricted funds */
export async function getRestrictedFunds(signal?: AbortSignal): Promise<OperationResult<SimpleFund[]>> {
  const endpoint = '/api/funds/restricted';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: SimpleFund[] = (await response.json()) as SimpleFund[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get composite benchmark by fund id */
export async function getCompositeBenchmarkByFund(
  fundId: string,
  signal?: AbortSignal,
): Promise<OperationResult<CompositeBenchmark>> {
  const endpoint = `/api/funds/${encodeURIComponent(fundId)}/compositebenchmark`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CompositeBenchmark = (await response.json()) as CompositeBenchmark;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get maps of the supported metadata fields */
export async function getMetadataOptions(signal?: AbortSignal): Promise<OperationResult<FundMetadataOptions>> {
  const endpoint = '/api/funds/metadata-options';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundMetadataOptions = (await response.json()) as FundMetadataOptions;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get search filter types */
export async function getFilterTypes(signal?: AbortSignal): Promise<OperationResult<FilterTypes>> {
  const endpoint = '/api/funds/search/options';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FilterTypes = (await response.json()) as FilterTypes;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the benchmark relative correlations of a fund to all nodes in the master portfolio. */
export async function getFundBenchmarkCorrelationsToMaster(
  id: string,
  benchmarkId?: string,
  start?: number,
  end?: number,
  signal?: AbortSignal,
): Promise<OperationResult<FundCorrelation>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/relative-correlation${buildQuery({ benchmarkId, start, end })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundCorrelation = (await response.json()) as FundCorrelation;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the correlations of a fund to all nodes in the master portfolio. */
export async function getFundCorrelationsToMaster(
  id: string,
  factorLensId?: number,
  start?: number,
  end?: number,
  residual?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<FundCorrelation>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/correlation${buildQuery({ factorLensId, start, end, residual })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundCorrelation = (await response.json()) as FundCorrelation;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a fund by ID */
export async function getFund(id: string, signal?: AbortSignal): Promise<OperationResult<Fund>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Fund = (await response.json()) as Fund;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a funds returns */
export async function getFundReturns(
  proxied: boolean,
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<number[][]>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/returns${buildQuery({ proxied })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: number[][] = (await response.json()) as number[][];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets the range and frequency of a fund's returns. Specify raw for unproxied, uninterpolated data. */
export async function getFundReturnsRange(
  raw: boolean,
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<FundReturnsRange>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/returns/range${buildQuery({ raw })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundReturnsRange = (await response.json()) as FundReturnsRange;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Invalidate cache for a particular fund */
export async function invalidateCacheForFund(fundId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/invalidate/${encodeURIComponent(fundId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** List proxy suggestions for an investment */
export async function autoproxyFund(
  id: string,
  extrapolate?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<AutoproxyResponse>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/autoproxy${buildQuery({ extrapolate })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AutoproxyResponse = (await response.json()) as AutoproxyResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function batchGetFundCustomMetadata(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: CustomMetadata }>> {
  const endpoint = '/api/funds/metadata';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: CustomMetadata } = (await response.json()) as { [key: string]: CustomMetadata };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function deleteFundYield(fundId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/${encodeURIComponent(fundId)}/metadata/yield`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getFundCustomMetadata(
  fundId: string,
  signal?: AbortSignal,
): Promise<OperationResult<CustomMetadata>> {
  const endpoint = `/api/funds/${encodeURIComponent(fundId)}/metadata/yield`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CustomMetadata = (await response.json()) as CustomMetadata;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function updateFundYield(
  fundId: string,
  body?: number,
  signal?: AbortSignal,
): Promise<OperationResult<YieldMetric>> {
  const endpoint = `/api/funds/${encodeURIComponent(fundId)}/metadata/yield`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: YieldMetric = (await response.json()) as YieldMetric;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Query for fund ids */
export async function getFundIds(body?: Partial<FundQuery>, signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/funds/query';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove funds from restricted list */
export async function removeFundRestrictions(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/funds/restricted';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove proxy for a fund */
export async function removeFundProxy(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/proxy/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Replace one fund with another fund */
export async function replaceFund(
  fundId: string,
  replacingFundId: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/replace/${encodeURIComponent(fundId)}/${encodeURIComponent(replacingFundId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve fund returns in CSV format */
export async function getCsv(fundId: string, signal?: AbortSignal): Promise<OperationResult<Blob>> {
  const endpoint = `/api/funds/csv/${encodeURIComponent(fundId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Blob = (await response.blob()) as Blob;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return list of proxy options, some of which are disabled */
export async function getProxyOptionsForFund(
  fundId: string,
  type?: ProxyTypeEnum,
  numLags?: number,
  signal?: AbortSignal,
): Promise<OperationResult<ProxyOption[]>> {
  const endpoint = `/api/funds/proxy/options/${encodeURIComponent(fundId)}${buildQuery({ type, numLags })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyOption[] = (await response.json()) as ProxyOption[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return metrics for this investment's proxy */
export async function investmentProxyMetrics(id: string, signal?: AbortSignal): Promise<OperationResult<ProxyMetrics>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/proxy-metrics`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyMetrics = (await response.json()) as ProxyMetrics;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return proxy option of custom proxy for fund */
export async function getCustomProxyOptionForFund(
  fundId: string,
  proxyId: string,
  type?: ProxyTypeEnum,
  numLags?: number,
  extrapolate?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<ProxyOption>> {
  const endpoint = `/api/funds/proxy/options/${encodeURIComponent(fundId)}/custom/${encodeURIComponent(proxyId)}${buildQuery({ type, numLags, extrapolate })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyOption = (await response.json()) as ProxyOption;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Run the remote fund sync */
export async function sync(
  publisher?: boolean,
  updateType?: UpdateTypeEnum,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/funds/data-fund-sync${buildQuery({ publisher, updateType })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** get the metadata for a fund */
export async function getFundMetadata(id: string, signal?: AbortSignal): Promise<OperationResult<FundMetadata>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/metadata`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundMetadata = (await response.json()) as FundMetadata;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** set the metadata for a fund */
export async function updateFundMetadata(
  id: string,
  body?: Partial<FundMetadata>,
  signal?: AbortSignal,
): Promise<OperationResult<FundMetadata>> {
  const endpoint = `/api/funds/${encodeURIComponent(id)}/metadata`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundMetadata = (await response.json()) as FundMetadata;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}

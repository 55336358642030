import React, { useContext } from 'react';
import ModalFooter from '../../modal/ModalFooter';
import Modal from '../../modal/Modal';
import { ModalSize } from '../../modal/components/ModalWrapper';
import styled, { ThemeContext } from 'styled-components';
import BannerMessage from '../../banner-message/BannerMessage';
import { isEmpty } from 'lodash';

export type DataModificationsCategory = {
  title: string;
  message: string;
  footerMessage: string;
  entities: string[];
};
type DataModificationsModalProps = {
  onClose: () => void;
  modifications: DataModificationsCategory[];
};

type DataModificationsContentProps = {
  modifications: DataModificationsCategory[];
  isReportDisclosure: boolean;
};

export const DataModificationsContent = ({ modifications, isReportDisclosure }: DataModificationsContentProps) => {
  if (isEmpty(modifications)) {
    return null;
  }
  return (
    <DataModificationsContentWrapper className="data-modifications-modal-content-wrapper">
      {modifications.map((modification) => (
        <SingleModificationWrapper className="single-data-modification-wrapper" key={modification.title}>
          {!isReportDisclosure && (
            <DataModificationCategoryTitle className="data-modification-category-title">
              {modification.title}
            </DataModificationCategoryTitle>
          )}
          {modification.message}
          <ul>
            {modification.entities.map((entity, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{entity}</li>
            ))}
          </ul>
          {!isReportDisclosure && modification.footerMessage}
        </SingleModificationWrapper>
      ))}
    </DataModificationsContentWrapper>
  );
};
export const DataModificationsModal = ({ onClose, modifications }: DataModificationsModalProps) => {
  const { Colors } = useContext(ThemeContext);

  if (isEmpty(modifications)) {
    return null;
  }
  return (
    <Modal size={ModalSize.Large} onClose={onClose} noPadding closeOnEsc closeOnOutsideClick>
      <DataModificationsModalWrapper className="data-modifications-modal-wrapper">
        <BannerMessage backgroundColor={Colors.Primary.Main} errorType="ok" title="Contains Venn-Modified Data" small />
        <DataModificationsContent isReportDisclosure={false} modifications={modifications} />
        <ModalFooter primaryLabel="Close" onPrimaryClick={onClose} primaryDisabled={false} />
      </DataModificationsModalWrapper>
    </Modal>
  );
};

const DataModificationsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const SingleModificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const DataModificationsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .data-modifications-modal-content-wrapper {
    padding: 10px 20px;
    gap: 10px;
  }
`;

const DataModificationCategoryTitle = styled.span`
  font-weight: bold;
`;

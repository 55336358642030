import React, { forwardRef, useContext } from 'react';
import styled from 'styled-components';
import type { Page } from 'venn-components';
import { StudioPrintSettingsContext, UserContext } from 'venn-components';
import { getAppTitle, GetColor, getTextThemeProvider, ZIndex } from 'venn-ui-kit';
import {
  FootnoteSymbols,
  STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE,
  STUDIO_DEFAULT_USER_DISCLAIMERS,
  useHasFF,
} from 'venn-utils';
import {
  DEFAULT_MARGIN,
  hasCustomMetadataOnPage,
  hasCustomMetricsOnPage,
  hasExcludedFeesOnPage,
  hasVennDataModificationsOnPage,
  selectedBlockIdState,
  studioCustomFooterText,
  studioHideFooterDisclaimer,
  studioHideFooterPagination,
  useRecoilValueWithDefault,
} from 'venn-state';
import Measure from 'react-measure';
import { useRecoilValue } from 'recoil';

interface PageFooterProps {
  reportLabPage?: Page;
  totalPage: number;
  currentPage: number;
  setStudioFooterHeight?: (height: number) => void;
}

const BOTTOM_OFFSET_PX = 10;

const DEFAULT_DISCLAIMERS = (organizationDisplayName?: string) => [
  `This report is provided to you by ${organizationDisplayName} for informational purposes only.  This report is prepared by ${organizationDisplayName} using ${getAppTitle()}${
    getTextThemeProvider().tsParam
  }, which is a tool that is designed to allow ${organizationDisplayName} to input certain metrics to produce this report. There is no guarantee that the performance and other metrics included herein are correct, complete, or accurate. This report is not investment advice or an offer to buy or sell a security, and should not be the basis for investment decision-making. Please see the back of this report for important information regarding the use of this report. Please contact ${organizationDisplayName} for further information.`,
];

export const EXCLUDED_FEES_DISCLAIMER = () =>
  `${FootnoteSymbols.excludedInvestments} Certain investments are excluded from the stated advisory fees; some investments’ returns are already presented net of fees, while other investments are not subject to advisory fees. See Disclosure page for additional details.`;

export const USER_DATA_DISCLAIMER = (organizationDisplayName?: string) =>
  `${FootnoteSymbols.customMetrics} This data has been added by ${organizationDisplayName} and as such, ${getAppTitle()}${
    getTextThemeProvider().tsParam
  } is not responsible for its accuracy.`;

export const VENN_DATA_MODIFICATIONS_DISCLAIMER = `${FootnoteSymbols.vennModifiedData} Certain investments have cash flow data modifications applied due to missing or outdated data; See Disclosure page for additional details`;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const PageFooter = forwardRef<HTMLDivElement, PageFooterProps>(
  ({ currentPage, reportLabPage, totalPage, setStudioFooterHeight }, forwardedRef) => {
    const selectedBlockId = useRecoilValue(selectedBlockIdState);
    const isBlockSelected = !!reportLabPage?.layout.find((l) => l.i === selectedBlockId);
    const { profileSettings } = useContext(UserContext);
    const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
    const hideFooterDisclaimer = useRecoilValue(studioHideFooterDisclaimer);
    const hideFooterPagination = useRecoilValue(studioHideFooterPagination);
    const customFooterText = useRecoilValue(studioCustomFooterText);
    const pageIndex = currentPage - 1;
    const hasExcludedFees = useRecoilValueWithDefault(hasExcludedFeesOnPage(pageIndex), false);
    const hasCustomMetrics = useRecoilValueWithDefault(hasCustomMetricsOnPage(pageIndex), false);
    const hasCustomMetadata = useRecoilValueWithDefault(hasCustomMetadataOnPage(pageIndex), false);
    const hasVennDataModifications = useRecoilValueWithDefault(hasVennDataModificationsOnPage(pageIndex), false);

    const organizationDisplayName = organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name;
    const organizationDefaultDisclaimer = STUDIO_DEFAULT_USER_DISCLAIMERS(organizationDisplayName);
    const disclaimerFontSize = organizationPrintSettings?.disclaimerFontSize ?? STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE;

    const enableReporting = useHasFF('studio_report_editor');
    const disclaimer = !hideFooterDisclaimer
      ? organizationPrintSettings?.disclaimer ?? organizationDefaultDisclaimer
      : '';
    const defaultDisclaimers = DEFAULT_DISCLAIMERS(organizationDisplayName);

    const hidePageFooter =
      hideFooterPagination &&
      hideFooterDisclaimer &&
      !customFooterText &&
      !(enableReporting && disclaimer) &&
      (!defaultDisclaimers || defaultDisclaimers.length === 0);

    return (
      <Footer moveToBackground={isBlockSelected} ref={forwardedRef}>
        <Measure
          bounds
          onResize={(contentRect) => {
            contentRect?.bounds?.height &&
              contentRect?.bounds?.height > 0 &&
              setStudioFooterHeight?.(contentRect?.bounds?.height + BOTTOM_OFFSET_PX);
          }}
        >
          {({ measureRef }) => {
            return (
              <div ref={measureRef} data-testid="qa-page-footer">
                {!hidePageFooter && (
                  <>
                    <Copy fontSize={disclaimerFontSize}>
                      {hasExcludedFees && <div>{EXCLUDED_FEES_DISCLAIMER()}</div>}
                      {(hasCustomMetrics || hasCustomMetadata) && (
                        <div>{USER_DATA_DISCLAIMER(organizationDisplayName)}</div>
                      )}
                      {hasVennDataModifications && <div>{VENN_DATA_MODIFICATIONS_DISCLAIMER}</div>}
                      {enableReporting && <div>{disclaimer}</div>}
                      {defaultDisclaimers.map((message) => (
                        <div key={message}>{message}</div>
                      ))}
                    </Copy>
                    <FlexBetween>
                      <div>
                        {!hideFooterPagination && (
                          <>
                            Page: {currentPage} / {totalPage}
                          </>
                        )}
                      </div>
                      <div>{customFooterText}</div>
                    </FlexBetween>
                  </>
                )}
              </div>
            );
          }}
        </Measure>
      </Footer>
    );
  },
);

export default PageFooter;

const Footer = styled.div<{ moveToBackground?: boolean }>`
  position: absolute;
  bottom: ${BOTTOM_OFFSET_PX}px;
  left: 20px;
  width: calc(100% - ${DEFAULT_MARGIN * 2}px);
  z-index: ${({ moveToBackground }) => (moveToBackground ? ZIndex.Background : ZIndex.StickyFront)};
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125rem;
`;

const Copy = styled.div<{ fontSize: number }>`
  white-space: pre-wrap;
  color: ${GetColor.GreyScale.Grey70};
  padding: 10px 0;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
  background: ${GetColor.White};
  overflow: hidden;
  font-size: ${({ fontSize }) => fontSize}pt;
  @media print {
    font-size: ${({ fontSize }) => fontSize}pt;
  }
`;

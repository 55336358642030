import moment from 'moment';
import type { UserProfileSettings } from 'venn-api';
import { getTitle } from '../pages/routes';
import activityService from '../user-activity';
import { v1 as uuidv1 } from 'uuid';
import { isNil, pickBy } from 'lodash';
import type { AnyObject } from '..';
import type { RangeType } from 'venn-ui-kit';

interface Traits {
  email?: string;
  firstName?: string;
  lastName?: string;
  organizationId?: number;
  organizationID?: number;
  organizationName?: string;
  workspaceId?: number;
  workspaceName?: string;
  company?: unknown;
  signupType?: string;
  actualUser?: boolean;
  isAccountAdmin: boolean;
  accountTier?: string;
  trialStatus?: string;
  createdAt?: string;
  investmentsAnalyzed?: number;
  investmentThreshold?: number;
}

interface TrackingOptions {
  integrations: {
    Amplitude: {
      session_id?: string;
    };
  };
  context: {
    groupId?: number;
    screen: {
      width: number;
      height: number;
      density: number;
    };
  };
}

const statusMap = {
  PENDING_ACTIVATION: 'whitelisted',
  BLACKLISTED: 'blacklisted',
  GREYLISTED_PENDING_ACTIVATION: 'greylisted',
};

const identify = (profile: UserProfileSettings, options: TrackingOptions) => {
  if (!profile?.user) {
    return;
  }

  const { organization, user } = profile;
  const traits: Traits = {
    email: user.email,
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    actualUser: !!profile?.actualUser,
    isAccountAdmin: organization?.accountAdministrator === user.email,
    accountTier: organization?.accountTier,
    trialStatus: organization?.trial?.status,
    createdAt: moment(user.created).toISOString(),
  };

  if (organization) {
    // organization.id in reality world means workspace id
    // organization.accountId in reality world means organization id
    traits.organizationId = organization.accountId;
    traits.organizationID = organization.accountId;
    traits.organizationName = organization.accountName;
    traits.company = {
      id: organization.accountId,
      name: organization.accountName,
    };
    traits.workspaceId = organization.id;
    traits.workspaceName = organization.name;
  }

  const integrations = {
    Intercom: {
      user_hash: profile.intercomHash,
    },
  };

  window.analytics?.identify(String(user?.id), removeNilValues(traits), {
    ...options,
    integrations: {
      ...options.integrations,
      ...integrations,
    },
  });
};

const dateFormat = (date?: number) => {
  if (date) {
    return moment.utc(date).format('YYYYMMDD');
  }
  return '';
};

const rangeFormat = (range?: RangeType): 'YTD' | '1Y' | '3Y' | '5Y' | 'FULL_HISTORY' | undefined => {
  switch (range) {
    case 'ytd':
      return 'YTD';
    case '1yr':
      return '1Y';
    case '3yr':
      return '3Y';
    case '5yr':
      return '5Y';
    case 'full':
      return 'FULL_HISTORY';
    default:
      return undefined;
  }
};

const group = (profile: UserProfileSettings, options: TrackingOptions) => {
  const org = profile?.organization;
  if (!org?.accountId) {
    return;
  }

  // accountId means a super group account id
  // several users could belong to one super account
  // several organizations/workspaces could belong to one super account
  const groupProperties = {
    groupType: 'Organization',
    name: org?.accountName,
    isPublicEquityIssuer: !!org?.issuesPublicEquity,
    accountAdmin: org?.accountAdministrator,
    accountTier: org?.accountTier,
    aumRange: org?.aum?.name,
    country: org?.country,
    jurisdication: org?.jurisdiction,
    locality: org?.locality,
    language: org?.language,
    createdAt: moment(org?.created).toISOString(),
    organizationType: org?.type,
    currency: org?.currency,
  };

  // Send group event
  window?.analytics?.group(String(org?.accountId), removeNilValues(groupProperties), options);
};

const removeNilValues = <T extends AnyObject>(obj: T): T => pickBy(obj, (value) => !isNil(value)) as T;

export const getCustomTrackingOptions = (): TrackingOptions => {
  const sessionId = activityService.getLocalStorageItemPerUser(activityService.SESSION_ID_LOCAL_STORAGE);
  const { orgId } = activityService;
  return {
    integrations: {
      Amplitude: {
        session_id: sessionId,
      },
    },
    context: {
      groupId: orgId,
      screen: {
        width: window?.screen?.width,
        height: window?.screen?.height,
        density: window?.devicePixelRatio,
      },
    },
  };
};

const AnalyticsUtils = (() => {
  let previousPageTitle: string;
  return {
    dateFormat,
    rangeFormat,
    identifyAndGroup: (profile: UserProfileSettings) => {
      identify(profile, getCustomTrackingOptions());
      group(profile, getCustomTrackingOptions());
    },

    identifyBeforeAccountCreation: (traits: Traits, status?: string) => {
      // If there's no userId, we create a random, anonymous identifier,
      // which will be overwritten once the user creates their account
      window.analytics?.identify(
        uuidv1(),
        {
          ...traits,
          // attempt to find the human-readable version of the status or show BE-generated status
          accountStatus: (status && statusMap[status]) || status,
        },
        getCustomTrackingOptions(),
      );
    },

    updateAccountStatus: (traits: Traits, status: string | undefined, userId: number) => {
      window.analytics?.identify(
        String(userId),
        {
          ...traits,
          // attempt to find the human-readable version of the status or show BE-generated status
          accountStatus: status ? statusMap[status] : undefined,
        },
        getCustomTrackingOptions(),
      );
    },

    page: (pathName: string) => {
      const title = getTitle(pathName);
      if (title && previousPageTitle !== title) {
        previousPageTitle = title;
        window?.analytics?.page(title, {}, getCustomTrackingOptions());
      }
    },
  };
})();

export default AnalyticsUtils;

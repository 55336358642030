export type FootnoteSymbol = '*' | '†' | '§' | '‡';

export type FootnoteType = 'excludedInvestments' | 'customMetrics' | 'pgaRank' | 'vennModifiedData';

export const FootnoteSymbols = {
  excludedInvestments: '*',
  customMetrics: '†',
  vennModifiedData: '‡',
  pgaRank: '*',
} as const satisfies { [key in FootnoteType]: FootnoteSymbol };

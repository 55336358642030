import type { FC, CSSProperties, InputHTMLAttributes, RefObject } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isString, isNil } from 'lodash';
import type { Omit } from '../types';
import { GetColor, Icon, IconPosition, TextAlign } from 'venn-ui-kit';
import CharactersInputCounter from '../characters-input-counter/CharactersInputCounter';
import AbsoluteErrorMessage from './AbsoluteErrorMessage';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: string;
  style?: CSSProperties;
  textInputStyle?: CSSProperties;
  icon?: JSX.Element[] | JSX.Element | string | null;
  iconPosition?: IconPosition;
  iconSize?: number;
  iconColor?: string;
  error?: boolean;
  errorMessage?: JSX.Element | string;
  errorColor?: string;
  errorAlign?: 'left' | 'right';
  textAlign?: TextAlign;
  value?: string;
  selectOnFocus?: boolean;
  setInputRef?: RefObject<HTMLInputElement>;
  role?: string;

  onChange?(value: string, name: string): void;

  onBlur?(event?: React.FocusEvent<HTMLInputElement>): void;

  onKeyUp?(event?: React.KeyboardEvent<HTMLInputElement>): void;

  charactersLimit?: number;
}
const handleEvent =
  (handler?: (value: string, name: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handler?.(event.target.value, event.target.name);
  };

const handleOnChangeWithCharactersLimit =
  (handler: ((value: string, name: string) => void) | undefined, charactersLimit: number) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const isOutOfLimit = event.target.value.length > charactersLimit;
    const value = isOutOfLimit ? event.target.value.substring(0, charactersLimit) : event.target.value;

    handler?.(value, name);
  };

const handleOnFocusEvent = (
  event: React.FocusEvent<HTMLInputElement>,
  handler?: (event: React.FocusEvent<HTMLInputElement>) => void,
  selectOnFocus = false,
) => {
  if (selectOnFocus) {
    event.currentTarget.select();
  }
  if (handler) {
    handler(event);
  }
};

const IconWrapper = styled.div<InputProps>`
  margin-top: 2px;
`;

const CustomIconWrapper = styled(IconWrapper)`
  margin-top: -1px;

  > span,
  > div {
    display: inline-block;
  }
`;

interface ContainerProps extends InputProps {
  isFocused: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 36px;
  border: 1px solid ${GetColor.Grey};
  border-radius: 3px;
  background-color: ${GetColor.White};
  border-color: ${(props) =>
    props.error ? props.errorColor || GetColor.Error : props.isFocused ? GetColor.Primary.Main : GetColor.Grey};

  @media print {
    border: none;
  }
`;

const StyledInput = styled.input<Pick<InputProps, 'textAlign'>>`
  flex: 1;
  width: 100%;
  margin: 0 10px;
  padding: 0;
  border: none;
  color: ${GetColor.Black};
  font-size: 13px;
  font-weight: normal;
  appearance: textfield;
  text-align: ${(props) => `${props.textAlign}`};
  // vertically position the text in the input correctly in IE
  line-height: normal;

  @media print {
    width: inherit;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
`;

export const Input: FC<React.PropsWithChildren<InputProps>> = ({
  className,
  style,
  icon,
  iconPosition = IconPosition.Left,
  iconColor = 'black',
  iconSize = 16,
  error = false,
  errorMessage = '',
  errorColor,
  errorAlign = 'left',
  textAlign = TextAlign.Left,
  role = 'textbox',
  onChange,
  onBlur,
  setInputRef,
  selectOnFocus,
  charactersLimit,
  ...inputProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const iconWrapper = isString(icon) ? (
    <IconWrapper>
      <Icon
        type={icon}
        style={{
          color: iconColor,
          fill: iconColor,
          height: iconSize,
          width: iconSize,
        }}
      />
    </IconWrapper>
  ) : (
    <CustomIconWrapper>{icon}</CustomIconWrapper>
  );
  const { onFocus, ...remainingInputProps } = inputProps;

  const handleOnChange = !isNil(charactersLimit)
    ? handleOnChangeWithCharactersLimit(onChange, charactersLimit)
    : handleEvent(onChange);

  return (
    <Container
      isFocused={isFocused}
      error={error || !!errorMessage}
      errorColor={errorColor}
      className={className}
      style={style}
    >
      {icon && iconPosition === 'left' && iconWrapper}
      <StyledInput
        textAlign={textAlign}
        ref={setInputRef}
        role={role}
        style={remainingInputProps.textInputStyle}
        {...remainingInputProps}
        onChange={handleOnChange}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setIsFocused(false);
          onBlur && onBlur(e);
        }}
        onFocus={(e) => {
          setIsFocused(true);
          handleOnFocusEvent(e, onFocus, selectOnFocus || false);
        }}
      />
      {charactersLimit ? (
        <CharactersInputCounter isFocused={isFocused} charactersLimit={charactersLimit} inputValue={inputProps.value} />
      ) : null}
      {icon && iconPosition === 'right' && iconWrapper}
      {errorMessage && (
        <AbsoluteErrorMessage className="qa-error" errorColor={errorColor} align={errorAlign}>
          {errorMessage}
        </AbsoluteErrorMessage>
      )}
    </Container>
  );
};

export default Input;

import type { SeriesOptionsType } from 'highcharts';
import { cloneDeep, merge } from 'lodash';
import type { RefObject } from 'react';
import React, { useContext, useMemo, useRef } from 'react';
import Measure from 'react-measure';
import { useRecoilValue } from 'recoil';
import styled, { css, ThemeContext } from 'styled-components';
import type { PrivatesAnalysisResponse } from 'venn-api';
import {
  blockCustomizableMetrics,
  blockExportMetadata,
  blockFonts,
  blockLimitedRequestSubjects,
  blockMetrics,
  blockPrivateAssetIsCumulative,
  blockRequestSubjects,
  isReportState,
  recoilBlockContentSize,
} from 'venn-state';
import { convertMultipleSeriesDataToExcel, Numbers } from 'venn-utils';
import { getCustomTooltip } from '../../../charts/analysis-charts/chart-config-logic';
import DownloadableContentBlock, { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import { useBlockId } from '../../contexts/BlockIdContext';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { getThirdPartyExportMessageRow } from '../../logic/exportUtils';
import { getMetricDisplayName } from '../../logic/privatesUtils';
import useExportUpdate from '../../logic/useExportUpdate';
import { useSetBlockSize } from '../../logic/useSetBlockSize';
import { getLineChartOptions } from './line-chart/utils';
import type { Serie } from './types';
import { GraphicSubjectLabel } from './GraphicSubjectLabel';
import { useAppPrintMode } from '../../../print/AppPrintMode';
import HighchartsUtils, { DEFAULT_QUARTERLY_TICK_INTERVAL } from '../../../utils/highcharts';
import { useChartReflow } from '../../../hooks/useChartReflow';
import { HISTORICAL_METRICS, useCashFlowChartMetricErrors } from './useCashFlowBlockMetricErrors';

type CashFlowChartProps = Readonly<{
  data: PrivatesAnalysisResponse;
  isExportable: boolean;
  downloadableContentRef?: RefObject<HTMLDivElement>;
}>;

const useExportMetaData = (projectionStartDate?: number) => {
  const blockId = useBlockId();
  const exportMetaData = useRecoilValue(blockExportMetadata(blockId));

  return {
    ...exportMetaData,
    projectionStartDate,
  };
};

const CashFlowChart = ({ data, isExportable, downloadableContentRef }: CashFlowChartProps) => {
  const blockId = useBlockId();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useChartReflow(blockId, chartRef);
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const theme = useContext(ThemeContext);
  const isReport = useRecoilValue(isReportState);
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const subject = useRecoilValue(blockLimitedRequestSubjects(blockId))[0];
  const cfSingleData = data?.cashFlows?.[0];
  const colors = theme.Colors;
  const cumulative = useRecoilValue(blockPrivateAssetIsCumulative(blockId));
  const exportMetaData = useExportMetaData(cfSingleData?.projectionAsOfDate);
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const metrics = useRecoilValue(blockCustomizableMetrics(blockId)).filter((x) => selectedMetrics.includes(x.key));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));
  const metric = metrics?.[0]?.analysisResultKey ?? '';
  const actual = metrics.some((x) => x.metricPath === 'actual');
  const projected = metrics.some((x) => x.metricPath === 'projected');
  const initial = metrics.some((x) => x.metricPath === 'initial');
  const onResize = useSetBlockSize(recoilBlockContentSize.transformedState(blockId));

  useCashFlowChartMetricErrors(data, blockId);

  const series: Serie[] = useMemo(() => {
    if (cfSingleData && cfSingleData.projected && cfSingleData.actual && cfSingleData.initial) {
      if (HISTORICAL_METRICS.includes(metric)) {
        const metricDisplayName = getMetricDisplayName(metric);
        return [
          {
            name: `Historical ${metricDisplayName}`,
            data: (cumulative ? cfSingleData?.actualCumulative : cfSingleData?.actual)?.[metric] ?? [],
            color: colors.CashFlowColor.Historical,
            type: 'column',
            visible: actual,
          } as Serie,
          {
            name: `Projected ${metricDisplayName}`,
            data: (cumulative ? cfSingleData?.projectedCumulative : cfSingleData?.projected)?.[metric] ?? [],
            color: colors.CashFlowColor.Projected,
            type: 'column',
            visible: projected,
          } as Serie,
          {
            name: `Typical ${metricDisplayName} Profile`,
            data: (cumulative ? cfSingleData?.initialCumulative : cfSingleData?.initial)?.[metric] ?? [],
            color: colors.CashFlowColor.Typical,
            type: 'line',
            visible: initial,
          } as Serie,
        ];
      }
    }
    return [];
  }, [
    cfSingleData,
    metric,
    actual,
    cumulative,
    colors.CashFlowColor.Historical,
    colors.CashFlowColor.Projected,
    colors.CashFlowColor.Typical,
    projected,
    initial,
  ]);

  const excelDataFn = useMemo(() => {
    return () => {
      if (!isExportable) {
        return [getThirdPartyExportMessageRow()];
      }

      return convertMultipleSeriesDataToExcel(
        series.map((s) => ({
          name: s.name,
          series: s.data,
        })),
        undefined,
        '$',
        exportMetaData.frequency,
      );
    };
  }, [exportMetaData.frequency, isExportable, series]);

  useExportUpdate({
    exportMetaData,
    excelDataFn,
    selectedRefId: blockId,
  });

  const options = useMemo(() => {
    const chartConfig = getLineChartOptions({
      series: [],
      theme,
      yAxisUnitFormat: 'ratio',
      frequency: 'DAILY',
      venncastEnabled: false,
      legendFontSize: legendFont.fontSizePt,
      axisFontSize: axisFont.fontSizePt,
    });

    const tooltipFormatter = (value?: number | string | null) => {
      if (typeof value !== 'number' || value === null || Number.isNaN(value)) {
        return '--';
      }
      const shortenedNum = Numbers.formatNumber(Numbers.shortenNumber(value), 2);
      return `${shortenedNum}${Numbers.getNumberShorthand(value)}`;
    };

    return {
      ...chartConfig,
      yAxis: merge(chartConfig.yAxis, {
        labels: {
          formatter: HighchartsUtils.allocationFormatter,
        },
        startOnTick: false,
        endOnTick: false,
        showFirstLabel: true,
        showLastLabel: true,
        maxPadding: HighchartsUtils.yAxisPaddingBasedOnFontSize(axisFont.fontSizePt),
      }),
      xAxis: merge(chartConfig.xAxis, {
        labels: {
          formatter: HighchartsUtils.quarterlyFormatter,
        },
        startOnTick: false,
        endOnTick: false,
        showFirstLabel: true,
        tickInterval: DEFAULT_QUARTERLY_TICK_INTERVAL,
      }),
      plotOptions: {
        ...chartConfig.plotOptions,
        column: {
          grouping: false,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 1,
          maxPointWidth: 20,
        },
      },
      tooltip: getCustomTooltip(undefined, 'QUARTERLY', colors, '$', tooltipFormatter), // TODO: '$' is hardcoded, this will need to change if we support other currencies.
      series: cloneDeep(series as SeriesOptionsType[]),
    };
  }, [series, theme, colors, legendFont.fontSizePt, axisFont.fontSizePt]);

  const chartComponent = (
    <Wrapper isReport={isReport}>
      <Measure bounds onResize={onResize}>
        {({ measureRef }) => (
          <CashFlowChartContainer ref={measureRef} isReport={isReport} data-testid="qa-cash-flow-chart">
            {subject && inPrintModeOrReportLab && <GraphicSubjectLabel subject={subject} />}
            <HighchartsReact
              ref={chartRef}
              highcharts={Highstock}
              containerProps={{ className: 'qa-cash-flow-highchart' }}
              options={options}
            />
          </CashFlowChartContainer>
        )}
      </Measure>
    </Wrapper>
  );

  if (inPrintModeOrReportLab) {
    return chartComponent;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: `Private Assets - ${cumulative ? 'Cumulative ' : ''}${getMetricDisplayName(metric)}`,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'PRIVATE_CASH_FLOW',
          relativeToBenchmark: false,
          userUploaded: false,
          subjectType: subjects?.[0]?.subjectType
            ? subjects?.[0]?.subjectType === 'PORTFOLIO'
              ? 'private-portfolio'
              : 'private-investment'
            : undefined,
          subjectId: subjects?.[0]?.id,
        },
        disabled: (series?.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      {chartComponent}
    </DownloadableContentBlock>
  );
};

const CashFlowChartContainer = styled.div<{ isReport: boolean }>`
  min-height: 100px;
  min-width: 200px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .qa-cash-flow-highchart {
    height: 100%;
  }

  ${({ isReport }) =>
    isReport &&
    css`
      width: 100%;
      height: 100%;

      .qa-cash-flow-highchart {
        width: 100%;
        height: 100%;
      }
    `}
`;

const Wrapper = styled.div<{ isReport: boolean }>`
  ${({ isReport }) =>
    isReport &&
    css`
      width: 100%;
      height: 100%;
    `}
`;

export default CashFlowChart;

import React, { useCallback, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { getAppTitle, GetColor, Headline3, Hint, Icon } from 'venn-ui-kit';
import { analyticsService, useModal } from 'venn-utils';
import { VideoModal } from 'venn-components';

const LEARN_VENN = `What is ${getAppTitle()}?`;

interface VennOnboardingVideoProps {
  locationOnPage: string;
}

const VennOnboardingVideo = ({ locationOnPage }: VennOnboardingVideoProps) => {
  const [isOpen, open, close] = useModal();
  const { Videos } = useContext(ThemeContext);

  const trackHeartbeat = useCallback(
    (timeElapsed?: number, timeTotal?: number) => {
      analyticsService.videoPlaying({
        locationOnPage,
        pageName: 'Home Page',
        videoTitle: LEARN_VENN,
        timeElapsed,
        timeTotal,
      });
    },
    [locationOnPage],
  );

  return (
    <>
      <VideoLauncher type="button" onClick={open}>
        <Play>
          <PlayIcon type="expand" prefix="fal" />
          <PlayIcon type="play" inner />
        </Play>
        <div>
          <Headline3>{LEARN_VENN}</Headline3>
          <Hint>{`Watch a video for a quick overview of ${getAppTitle()}.`}</Hint>
        </div>
      </VideoLauncher>
      {isOpen && (
        <VideoModal onClose={close} trackHeartbeat={trackHeartbeat}>
          <source src={Videos.productTourVideo} type="video/mp4" />
        </VideoModal>
      )}
    </>
  );
};

export default VennOnboardingVideo;

const Play = styled.div`
  position: relative;
  left: 50px;
  width: 50px;
`;

const PlayIcon = styled(Icon)<{ inner?: boolean }>`
  ${({ inner }) => css`
    color: ${inner ? GetColor.Black : GetColor.Grey};
    font-size: ${inner ? '18px' : '60px'};
    top: ${inner ? '20px' : '0'};
    left: ${inner ? '-45px' : '-65px'};
  `}
  position: absolute;
`;

const VideoLauncher = styled.button`
  padding-left: 40px;
  display: flex;
`;

import React, { useMemo } from 'react';
import { get } from 'lodash';
import type { ReturnCell } from 'venn-api';
import { convertMultipleSeriesDataToExcel, getCumulativeVenncastExportData, useHasFF } from 'venn-utils';
import type { GeneralChartProps, Serie } from './types';
import LineChart from './LineChart';
import BarChart from './BarChart';
import { DownloadableContentBlock } from '../../../content-block';
import useExportUpdate from '../../logic/useExportUpdate';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import StyledEmptyState from '../StyledEmptyState';
import { formatExportableSubjectWithOptionalFee, formatSubjectWithOptionalFee } from '../../../legend';
import { getThirdPartyExportMessageRow } from '../../logic/exportUtils';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { useRecoilValue } from 'recoil';
import { viewShowVenncast } from 'venn-state';

// We want to treat historical drawdown differently
const PERCENTAGE_DRAWDOWN_KEY = 'historicalDrawdown';

/** Timeseries type chart such as line chart or normal bar chart */
const TimeseriesChart = ({
  metrics,
  selectedBlock,
  analyses,
  requests,
  downloadableContentRef,
  height,
  subjectColors,
  selectedRefId,
  exportMetaData,
  inPrintMode,
}: GeneralChartProps) => {
  const metric = metrics ? metrics[0] : undefined;
  const hasStudioVenncastFF = useHasFF('studio_venncast_ff');
  const showVennCast = useRecoilValue(viewShowVenncast(selectedRefId));
  const addVenncastSeries = hasStudioVenncastFF && showVennCast && requests.length === 1;

  const [series, exportableSeries] = useMemo(() => {
    const series: Serie[] = [];
    const exportableSeries: Serie[] = [];
    requests.forEach((req, index) => {
      const analysis = analyses?.[index]?.find((analysis) => analysis?.analysisType === metric?.analysisType);
      const venncastAnalysis =
        addVenncastSeries && analyses?.[index]?.find((analysis) => analysis?.analysisType === 'VENNCAST');
      if (!analysis || !metric) {
        return;
      }

      const analysisResult = get(analysis, metric.analysisResultKey!);
      const venncastResult = venncastAnalysis && get(venncastAnalysis, 'venncast');
      if (!analysisResult) {
        return;
      }

      const ts = metric.metricPath ? get(analysisResult[0], metric.metricPath) : analysisResult[0];
      if (!ts) {
        return;
      }
      const benchmarkName = req.relative && req.benchmark ? ` (Relative to: ${req.benchmark.name})` : '';
      const name = `${formatSubjectWithOptionalFee(req.subject)}${benchmarkName}`;
      const exportableName = `${formatExportableSubjectWithOptionalFee(req.subject)}${benchmarkName}`;
      const seriesData = convertToTimeseries(ts);
      const serie = {
        name,
        data: seriesData,
        color: subjectColors[index],
        ...(venncastResult?.[0] ? { venncast: venncastResult[0] } : undefined),
      };
      const exportableSerie = {
        name: exportableName,
        data: seriesData,
        ...(venncastResult?.[0] ? { venncast: venncastResult[0] } : undefined),
      };
      series.push(serie);
      // Prevent export return stream that could contain third party data
      if (analysis.exportable?.[0]) {
        exportableSeries.push(exportableSerie);
      }
    });
    return [series, exportableSeries];
  }, [requests, metric, analyses, addVenncastSeries, subjectColors]);

  const defaultHeader =
    selectedBlock?.relativeToBenchmark && metric?.relativeLabel
      ? metric.relativeLabel
      : !metric
        ? 'Builder Chart'
        : metric.label;

  const showThirdPartyDataExportMessage = series.length && !exportableSeries.length;
  // We useMemo rather than useCallback because we need to know in advance if we'll have data or not have data (return undefined),
  // so that downstream components like the export button know if data is available.
  const excelDataFn = useMemo(() => {
    if (showThirdPartyDataExportMessage) {
      return () => [getThirdPartyExportMessageRow()];
    }

    if (addVenncastSeries && exportableSeries.length) {
      const venncast = exportableSeries[0].venncast;
      if (venncast) {
        return () => getCumulativeVenncastExportData(exportableSeries[0].name, venncast, true);
      }
      return undefined;
    }

    return () =>
      convertMultipleSeriesDataToExcel(
        exportableSeries.map((s) => ({ name: s.name, series: s.data })),
        metric?.dataType !== 'NUMERIC',
      );
  }, [showThirdPartyDataExportMessage, exportableSeries, addVenncastSeries, metric?.dataType]);

  useExportUpdate({ exportMetaData, excelDataFn, selectedRefId });

  const Component = selectedBlock?.infoGraphicType === 'LINE' ? LineChart : BarChart;

  const component =
    series.length > 0 ? (
      <Component
        start={requests?.[0]?.dateRange.from}
        end={requests?.[0]?.dateRange.to}
        series={series}
        frequency={requests?.[0]?.frequency ?? 'MONTHLY'}
        yAxisUnitFormat={metric?.dataType === 'NUMERIC' ? 'ratio' : 'percent'}
        inPrintMode={inPrintMode}
        hasDrawdownLines={metric?.key === PERCENTAGE_DRAWDOWN_KEY}
        height={height}
        venncastEnabled={hasStudioVenncastFF && showVennCast}
        subjectType={requests?.[0].subject?.subjectType === 'PORTFOLIO' ? 'portfolio' : 'investment'}
      />
    ) : (
      <StyledEmptyState selectedRefId={selectedRefId} header="Unable to run analysis" message="Chart is empty" />
    );

  if (inPrintMode) {
    return component;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: series.length === 0,
        target: downloadableContentRef,
      }}
    >
      {component}
    </DownloadableContentBlock>
  );
};

export default TimeseriesChart;

const convertToTimeseries = (ts: number[][] | ReturnCell[]): number[][] => {
  if (!ts) {
    return [];
  }
  if (Array.isArray(ts[0])) {
    return ts as number[][];
  }

  return (ts as ReturnCell[]).filter((cell) => cell.cellTypes.includes('ANALYSIS')).map((t) => [t.date, t.val]);
};

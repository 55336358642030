import { atom, selector } from 'recoil';
import type { AnalysisViewTypeEnum, AppUser } from 'venn-api';
import { resetOnStudioReset, unsavedChangesEffect } from '../../effects';
import { isReport, isViewTemplate } from 'venn-utils';

/** The type of view currently loaded in studio or report lab */
export const analysisViewTypeState = atom<AnalysisViewTypeEnum>({
  key: 'analysisViewTypeState',
  default: 'ANALYSIS',
  effects: [resetOnStudioReset, unsavedChangesEffect],
});

export const isTemplateState = selector<boolean>({
  key: 'isTemplateState',
  get: ({ get }) => {
    return isViewTemplate(get(analysisViewTypeState));
  },
});

export const analysisViewOwnerContextIdState = atom<string | undefined>({
  key: 'analysisViewOwnerContextIdState',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const analysisViewNameState = atom<string | undefined>({
  key: 'analysisViewNameState',
  default: undefined,
  effects: [unsavedChangesEffect],
});

/** Whether the current view is a report lab view. */
export const isReportState = selector<boolean>({
  key: 'isReportState',
  get: ({ get }) => {
    const viewType = get(analysisViewTypeState);
    return isReport(viewType);
  },
});

/** The id of the currently loaded analysis view */
export const analysisViewIdState = atom<string | undefined>({
  key: 'analysisViewIdState',
  default: undefined,
  effects: [resetOnStudioReset],
});

/**
 * The ref id of the currently loaded analysis view.
 * Used as a stable id for the view before the view is saved.
 */
export const analysisViewRefIdState = atom<string | undefined>({
  key: 'analysisViewRefIdState',
  default: undefined,
  effects: [resetOnStudioReset],
});

/**
 * Returns the view refId, or id if the refId is missing.
 * The refId is preferred if it exists because it is added by the client and stable before and after a save, whereas the id can change after a save.
 */
export const analysisViewCombinedIdState = selector<string | undefined>({
  key: 'analysisViewCombinedIdState',
  get: ({ get }) => {
    return get(analysisViewRefIdState) ?? get(analysisViewIdState);
  },
});

export const analysisViewSystemTemplateState = atom<string>({
  key: 'analysisViewSystemTemplateState',
  default: 'custom',
});

export const analysisViewOwnerState = atom<AppUser | undefined>({
  key: 'analysisViewOwnerState',
  default: undefined,
});
